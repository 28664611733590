import axios from 'axios'
import Store from '@/store'
import { GetOneCommission } from '@/Methods/GetOneCommission'
import Decimal from 'decimal.js'
// import Store from '../store'

const state = () => ({
  commissionByTypes: {},
  lionCourseByCurrency: {}
})

const mutations = {
  setCommissionByTypes (state, val) {
    state.commissionByTypes = val
  },
  setLionCourseByCurrency (state, val) {
    state.lionCourseByCurrency = val
  }
}

const actions = {
  getCommission (context, operation) {
    return axios({
      method: 'GET',
      url: `${Store.state.backEndAddress}/system/commission`
    })
      .then(response => {
        if (response.data.status === 200) {
          // console.log('get', response.data.payload)
          return GetOneCommission(response.data.payload, operation)
        } else {
          throw new Error('Err')
        }
      })
  },
  getLionCommission ({ commit }) {
    return axios({
      method: 'GET',
      url: `${Store.state.backEndAddress}/system/exchange-rates/lion`
    })
      .then(response => {
        if (response.data.status === 200) {
          const result = response.data.payload?.reduce((acc, courseItem) => {
            acc[courseItem.currency_type] = courseItem
            return acc
          }, {})
          commit('setLionCourseByCurrency', result)
        }
        return response.data.payload
      })
  },
  getAllCommissions (context) {
    return axios({
      method: 'GET',
      url: `${Store.state.backEndAddress}/system/commission`
    })
      .then(response => {
        if (response.data.status === 200) {
          const commissionByTypes = {
            commission: {},
            commissionCE: {}
          }
          const {
            ae_request_commission,
            commission_list,
            cryptoenter_commission_list,
            gas_commission
          } = response.data.payload

          commission_list.forEach(item => {
            commissionByTypes.commission[item.process_type] = item
          })
          cryptoenter_commission_list.forEach(item => {
            commissionByTypes.commissionCE[item.process_type] = item
          })
          commissionByTypes.gasCommission = gas_commission
          context.commit('setCommissionByTypes', commissionByTypes)
          context.commit('setAeRequestCommission', ae_request_commission, { root: true })
          return response.data.payload
        } else {
          throw new Error(response.data.message)
        }
      })
  }
}

const getters = {
  getCommissionTypes: state => Object.keys(state.commissionByTypes.commission || {}),
  getCommissionCETypes: state => Object.keys(state.commissionByTypes.commissionCE || {}),
  getCommissionByType: state => commType => state.commissionByTypes.commission?.[commType] || {},
  getCommissionCEByType: state => commType => state.commissionByTypes.commissionCE?.[commType] || {},
  getLionCourseCurrencies: state => Object.keys(state.lionCourseByCurrency || {}),
  getLionCourseByCurrency: (state, getters, rootState) => currency => {
    let curr = rootState.tokensNameMap[currency]?.code
    if (!curr) curr = rootState.tokensCodeMap[currency]?.code
    else curr = currency
    return state.lionCourseByCurrency?.[curr] || {}
  },
  getCommissionAmount: (state, getters) => (processType, amount, currency) => {
    if (!amount) return 0
    const { min, max, commission_percent } = getters.getCommissionByType(processType) || {}
    // console.log({ min, max, commission_percent, processType, amount, currency, liontether: getters.getLionCourseByCurrency(currency) })
    const calculatedCommission = new Decimal(amount).div(100).mul(commission_percent).mul(getters.getLionCourseByCurrency(currency)?.rate || 0)
    let result = Decimal.max(min, calculatedCommission)
    if (max) result = Decimal.min(result, max)
    return result
  },
  getCommissionCEAmount: (state, getters) => (processType, amount, currency) => {
    if (!amount) return 0
    const { min, max, commission_percent } = getters.getCommissionCEByType(processType) || {}
    const calculatedCommission = new Decimal(amount).div(100).mul(commission_percent).mul(getters.getLionCourseByCurrency(currency)?.rate || 0)
    let result = Decimal.max(min, calculatedCommission)
    if (max) result = Decimal.min(result, max)
    return result
  },
}

export default {
  actions,
  state,
  mutations,
  getters
}
