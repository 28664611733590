import axios from 'axios'
import Store from '@/store'
import { CreateNewExchangeWithUser } from '@/Methods/CreateNewExchangeWithUser'
import { accept, cancel, reject } from '@/Methods/Request'
import { currencies } from '@/models/Currencies'
import { buildOfferObjectByDate, createOfferListV2, createOffersList, getStateOffersName } from '@/Methods/OffersLists'
import { createDateOfTimeStamp } from '@/Methods/GlobalMethods'
import moment from "moment-mini";
import CreateNewDepositFiatRequest from "@/Methods/CreateNewDepositFiatRequest";

/**
 * Функция получения одной заявки
 * @param listAllOffers - спсиок всех заявок
 * @param operationId - operationId необходимой заявки
 */
let getOfferInfo = (listAllOffers, operationId) => {
  for (let offer in listAllOffers) {
    if (offer === operationId) {
      let operation = listAllOffers[offer].request
      let operationInfo = {
        inInfo: {
          amount: operation.in.amount.amount,
          currency: operation.in.amount.currency_type,
          userId: operation.in.user_account_info.user_id,
          walletId: operation.in.user_account_info.wallet_id,
          accountId: operation.in.user_account_info.account_id
        },
        outInfo: {
          amount: operation.out.amount.amount,
          currency: operation.out.amount.currency_type,
          userId: operation.out.user_account_info.user_id,
          walletId: operation.out.user_account_info.wallet_id,
          accountId: operation.out.user_account_info.account_id
        }
      }
      return operationInfo
    }
  }
}

const state = () => ({
  // V2
  offers: {
    inputOffers: {}, // Входящие завяки на обмен
    inputHistoryOffers: {}, // История входящих заявок на обмен
    outputOffers: {}, // Исходящие заявки на обмен
    outputHistoryOffers: {}, // История исходящих заявок на обмен
  },
})

const actions = {
  createNewDepositFiatRequest: (context, dataForSend) => {
    console.log({ dataForSend }, dataForSend.RecipientCurrency)
    Store.commit('SetLoaderFlag', true)
    const { rootState } = context
    dataForSend.RecipientCurrency = rootState.tokensCodeMap[dataForSend.RecipientCurrency].name
    dataForSend.SenderCurrency = rootState.tokensCodeMap[dataForSend.SenderCurrency].name
    dataForSend.OfferRecordStatus = 'OfferRecordStatusRequest'
    return CreateNewDepositFiatRequest(dataForSend)
      .then(response => {
        return response
      })
      .then(sendingData => {
        return axios({
          method: 'POST',
          url: `${Store.state.backEndAddress}/fiat/deposit`,
          data: JSON.stringify(sendingData),
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept-Language': 'ru-RU'
          }
        })
          .then(response => {
            return response
          })
      })
      .catch(err => {
        return err
      })
  },
  createNewExchangeWithUser: (context, dataForSend) => {
    Store.commit('SetLoaderFlag', true)
    const { rootState } = context
    dataForSend.RecipientCurrency = rootState.tokensCodeMap[dataForSend.RecipientCurrency].name
    dataForSend.SenderCurrency = rootState.tokensCodeMap[dataForSend.SenderCurrency].name
    dataForSend.OfferRecordStatus = 'OfferRecordStatusRequest'
    return CreateNewExchangeWithUser(dataForSend)
      .then(response => {
        return response
      })
      .then(sendingData => {
        return axios({
          method: 'POST',
          url: `${Store.state.backEndAddress}/exchange/offers`,
          data: JSON.stringify(sendingData),
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept-Language': 'ru-RU'
          }
        })
          .then(response => {
            return response
          })
      })
      .catch(err => {
        return err
      })
  },

  getAllRequests: context => {
    return axios({
      method: 'GET',
      url: `${Store.state.backEndAddress}/exchange/offers`
    })
      .then(response => {
        if (response.data.status === 200) {
          return createOffersList(response.data.payload.offers)
        }
        throw new Error(`Упс!`)
      })
  },
  getAllRequestsV2: context => {
    return axios.get(`${Store.state.backEndAddress}/exchange/offers`)
      .then(response => {
        // console.log({ response })
        if (response.data.status === 200) {
          context.commit('setOffers', createOfferListV2(response.data.payload.offers))
        } else throw new Error(`Упс!`)
      })
  },
  /* eslint-disable camelcase */
  rejectRequest: (context, dataForSend) => {
    Store.commit('SetLoaderFlag', true)
    let action_set_offer_status_by_operation_id = {
      keys: [dataForSend.operationId],
      value: 'OfferRejected'
    }
    let operation_id = {
      operation_id: dataForSend.operationId,
      user_account_info: {
        account_id: String,
        user_id: String,
        wallet_id: String
      }
    }
    return axios({
      method: 'GET',
      url: `${Store.state.backEndAddress}/exchange/offers`
    })
      .then(response => {
        if (response.data.status === 200) {
          return getOfferInfo(response.data.payload.offers, dataForSend.operationId)
        } else {
          throw new Error()
        }
      })
      .then(operation => {
        operation_id.user_account_info.user_id = operation.outInfo.userId
        operation_id.user_account_info.account_id = operation.outInfo.accountId
        operation_id.user_account_info.wallet_id = operation.outInfo.walletId
        return reject(action_set_offer_status_by_operation_id, operation_id, dataForSend.key)
      })
      .then(response => {
        return axios({
          method: 'POST',
          url: `${Store.state.backEndAddress}/exchange/offers/reject`,
          data: JSON.stringify(response),
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept-Language': 'ru-RU'
          }
        })
          .then(response => {
            if (response.data.status === 200) {
              return response
            } else {
              throw new Error()
            }
          })
          .catch(err => {
            throw new Error(err)
          })
      })
      .catch(err => {
        return err
      })
  },

  acceptCashierRequest: (context, dataForSend) => {
    // FIXME пожалуйста, я очень хочу рефакторинг
    Store.commit('SetLoaderFlag', true)
    let block_option = {
      amount: {
        amount: String,
        currency_type: String
      },
      in_user: {
        account_id: String,
        user_id: String,
        wallet_id: String
      },
      out_user: {
        account_id: String,
        user_id: String,
        wallet_id: String
      },
      process_type: 'EXCHANGE_WITH_BOTH_COMMISSION'
    }
    let action_set_offer_status_by_operation_id = {
      keys: [dataForSend.operationId],
      value: 'OfferAccepted'
    }
    let operation_id = {
      operation_id: dataForSend.operationId,
      user_account_info: {
        account_id: String,
        user_id: String,
        wallet_id: String
      }
    }
    return axios({
      method: 'GET',
      url: `${Store.state.backEndAddress}/exchange/offers`
    })
      .then(response => {
        if (response.data.status === 200) {
          return getOfferInfo(response.data.payload.offers, dataForSend.operationId)
        } else {
          throw new Error('NotRequests')
        }
      })
      .then(operation => {
        block_option.amount.amount = operation.inInfo.amount
        block_option.amount.currency_type = operation.inInfo.currency
        block_option.in_user.account_id = operation.outInfo.accountId
        block_option.in_user.user_id = operation.outInfo.userId
        block_option.in_user.wallet_id = operation.outInfo.walletId
        block_option.out_user.account_id = operation.inInfo.accountId
        block_option.out_user.user_id = operation.inInfo.userId
        block_option.out_user.wallet_id = operation.inInfo.walletId
        operation_id.user_account_info.user_id = operation.outInfo.userId
        operation_id.user_account_info.account_id = operation.outInfo.accountId
        operation_id.user_account_info.wallet_id = operation.outInfo.walletId
        return accept(block_option, action_set_offer_status_by_operation_id, operation_id, dataForSend.key)
      })
      .then(response => {
        return axios({
          method: 'POST',
          url: `${Store.state.backEndAddress}/exchange/offers/accept`,
          data: JSON.stringify(response),
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept-Language': 'ru-RU'
          }
        })
          .then(response => {
            if (response.data.status === 200) {
              return true
            } else {
              throw new Error()
            }
          })
          .catch(err => {
            throw new Error(err)
          })
      })
      .catch(err => {
        throw new Error(err)
      })
  },

  acceptRequest: (context, dataForSend) => {
    Store.commit('SetLoaderFlag', true)
    let block_option = {
      amount: {
        amount: String,
        currency_type: String
      },
      in_user: {
        account_id: String,
        user_id: String,
        wallet_id: String
      },
      out_user: {
        account_id: String,
        user_id: String,
        wallet_id: String
      },
      process_type: 'EXCHANGE_WITH_BOTH_COMMISSION'
    }
    let action_set_offer_status_by_operation_id = {
      keys: [dataForSend.operationId],
      value: 'OfferAccepted'
    }
    let operation_id = {
      operation_id: dataForSend.operationId,
      user_account_info: {
        account_id: String,
        user_id: String,
        wallet_id: String
      }
    }
    return axios({
      method: 'GET',
      url: `${Store.state.backEndAddress}/exchange/offers`
    })
      .then(response => {
        if (response.data.status === 200) {
          return getOfferInfo(response.data.payload.offers, dataForSend.operationId)
        } else {
          throw new Error('NotRequests')
        }
      })
      .then(operation => {
        block_option.amount.amount = operation.inInfo.amount
        block_option.amount.currency_type = operation.inInfo.currency
        block_option.in_user.account_id = operation.outInfo.accountId
        block_option.in_user.user_id = operation.outInfo.userId
        block_option.in_user.wallet_id = operation.outInfo.walletId
        block_option.out_user.account_id = operation.inInfo.accountId
        block_option.out_user.user_id = operation.inInfo.userId
        block_option.out_user.wallet_id = operation.inInfo.walletId
        operation_id.user_account_info.user_id = operation.outInfo.userId
        operation_id.user_account_info.account_id = operation.outInfo.accountId
        operation_id.user_account_info.wallet_id = operation.outInfo.walletId
        return accept(block_option, action_set_offer_status_by_operation_id, operation_id, dataForSend.key)
      })
      .then(response => {
        return axios({
          method: 'POST',
          url: `${Store.state.backEndAddress}/exchange/offers/accept`,
          data: JSON.stringify(response),
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept-Language': 'ru-RU'
          }
        })
          .then(response => {
            if (response.data.status === 200) {
              return true
            } else {
              throw new Error()
            }
          })
          .catch(err => {
            throw new Error(err)
          })
      })
      .catch(err => {
        throw new Error(err)
      })
  },

  cancelRequest: (context, dataForSend) => {
    Store.commit('SetLoaderFlag', true)
    let action_set_offer_status_by_operation_id = {
      keys: [dataForSend.operationId],
      value: 'OfferCanceled'
    }
    let operation_id = {
      operation_id: dataForSend.operationId,
      user_account_info: {
        account_id: String,
        user_id: String,
        wallet_id: String
      }
    }
    return axios({
      method: 'GET',
      url: `${Store.state.backEndAddress}/exchange/offers`
    })
      .then(response => {
        if (response.data.status === 200) {
          return getOfferInfo(response.data.payload.offers, dataForSend.operationId)
        } else {
          throw new Error()
        }
      })
      .then(operation => {
        operation_id.user_account_info.user_id = operation.outInfo.userId
        operation_id.user_account_info.account_id = operation.outInfo.accountId
        operation_id.user_account_info.wallet_id = operation.outInfo.walletId
        return cancel(action_set_offer_status_by_operation_id, operation_id, dataForSend.key)
      })
      .then(response => {
        return axios({
          method: 'POST',
          url: `${Store.state.backEndAddress}/exchange/offers/cancel`,
          data: response,
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept-Language': 'ru-RU'
          }
        })
          .then(response => {
            if (response.data.status === 200) {
              return true
            } else {
              throw new Error()
            }
          })
          .catch(err => {
            throw new Error(err)
          })
      })
      .catch(err => {
        throw new Error(err)
      })
  }
  /* eslint-enable camelcase */
}

const mutations = {
  newRequestHandler: (state, { offersName, data }) => {
    state.offers[offersName] = buildOfferObjectByDate([data], state.offers[offersName])
  },
  // canceledRequestHandler: (state, offer) => {
  //   const offersObjectName = getStateOffersName(offer)
  //   const dateKey = createDateOfTimeStamp(offer.timestamp)
  //   const offersArr = state.offers[offersObjectName][dateKey]
  //   const idx = offersArr?.findIndex(i => i.operationId === offer.operation_id)
  //   offersArr.splice(idx, 1)
  // },
  acceptedRejectedRequestHandler: (state, offer) => {
    const offersObjectNameForDelete = getStateOffersName(offer)
    const offersObjectNameForPush = getStateOffersName(offer, true)
    const dateKey = createDateOfTimeStamp(offer.timestamp)
    const offersArrForDelete = state.offers[offersObjectNameForDelete][dateKey]
    const idx = offersArrForDelete?.findIndex(i => i.operationId === offer.operation_id)
    const offersDateForPush = state.offers[offersObjectNameForPush]
    const newOffer = offersArrForDelete[idx]
    newOffer.offerStatus = [offer.offer_status]
    if (offersDateForPush[dateKey]) {
      offersDateForPush[dateKey].push(newOffer)
      offersDateForPush[dateKey] = offersDateForPush[dateKey].sort((a, b) => b.timestamp - a.timestamp)
    }
    else offersDateForPush[dateKey] = [newOffer]
    offersArrForDelete.splice(idx, 1)
  },
  setOffers: (state, data) => {
    state.offers = data
  },
}

const getters = {
  getInputOffers: state => state.offers.inputOffers,
  getInputHistoryOffers: state => state.offers.inputHistoryOffers,
  getOutputOffers: state => state.offers.outputOffers,
  getOutputHistoryOffers: state => state.offers.outputHistoryOffers,
  getInputOffersSortedDates: (state, getters) => {
    return Object.keys(getters.getInputOffers || {})
      .filter(offerDate => getters.getInputOffers[offerDate]?.length)
      .sort((aDate, bDate) => moment(bDate, 'DD.MM.YYYY').unix() - moment(aDate, 'DD.MM.YYYY').unix())
  },
  getInputHistoryOffersSortedDates: (state, getters) => {
    return Object.keys(getters.getInputHistoryOffers || {})
      .filter(offerDate => getters.getInputHistoryOffers[offerDate]?.length)
      .sort((aDate, bDate) => moment(bDate, 'DD.MM.YYYY').unix() - moment(aDate, 'DD.MM.YYYY').unix())
  },
  getOutputOffersSortedDates: (state, getters) => {
    return Object.keys(getters.getOutputOffers || {})
      .filter(offerDate => getters.getOutputOffers[offerDate]?.length)
      .sort((aDate, bDate) => moment(bDate, 'DD.MM.YYYY').unix() - moment(aDate, 'DD.MM.YYYY').unix())
  },
  getOutputHistoryOffersSortedDates: (state, getters) => {
    return Object.keys(getters.getOutputHistoryOffers || {})
      .filter(offerDate => getters.getOutputHistoryOffers[offerDate]?.length)
      .sort((aDate, bDate) => moment(bDate, 'DD.MM.YYYY').unix() - moment(aDate, 'DD.MM.YYYY').unix())
  },
}

export default {
  state,
  actions,
  mutations,
  getters
}
